import styled from 'styled-components'

const Line = styled.div`
  width: 80vw;
  height: 1px;
  margin: 5px;
  background: #aaa;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function App() {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="196" height="45" viewBox="0 0 196 45">
        <text id="MaintAuto" transform="translate(0 37)" fill="#1f1f1f" font-size="40" font-family="Ubuntu-Regular, Ubuntu"><tspan x="0" y="0">Maint</tspan><tspan y="0" fill="#ed3d3d" font-family="Ubuntu-Medium, Ubuntu" font-weight="500">Auto</tspan></text>
      </svg>
      <Line/>
      <p>Vehicle maintenance made easy</p>
    </Wrapper>
  );
}

export default App;
